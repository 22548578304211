var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.categoryWrap},[(_vm.loading)?_c('div',{class:_vm.$style.overlay},[_c('CustomLoader')],1):_vm._e(),_vm._l((_vm.categoriesComputed),function(item,index){return _c('div',{key:item.code,class:_vm.$style.item},[_c('div',{key:item.code,class:[
        _vm.$style.expenseContainer,
        item.key === _vm.category ? _vm.$style.active : null,
        _vm.categoriesComputed[index].isEmpty ? _vm.$style.empty : null,
      ],on:{"click":function($event){return _vm.onSelectCategory(index)}}},[_c('div',{class:_vm.$style.nameIcon},[_c('div',{class:_vm.$style.icon},[_c('v-icon',{attrs:{"disabled":_vm.categoriesComputed[index].isEmpty,"color":"blue"}},[_vm._v(_vm._s(_vm.ICONS[item.key]))])],1),_c('div',{class:_vm.$style.name},[_vm._v(_vm._s(item.name))])]),_c('div',{class:_vm.$style.info},[_c('div',{class:_vm.$style.reclaimValue},[_c('span',[_vm._v(_vm._s(_vm.$t('TOTAL_VAT_RECLAIM_AMOUNT')))]),_vm._v(" "+_vm._s(_vm.getReclaimValue(item))+" ")]),_c('div',{class:_vm.$style.expenses},[_c('span',[_vm._v(_vm._s(_vm.$t('EXPENSES')))]),_vm._v(" "+_vm._s(_vm.getExpensesCount(item))+" ")])])]),_c('div',{class:[
        _vm.$style.expensesList,
        item.key === _vm.category ? _vm.$style.expanded : null,
      ]},[(item.key === _vm.category)?_c('ExpenseList',{attrs:{"expenses":_vm.expenses,"pagination":_vm.pagination,"set-page":_vm.setPage,"disabled":_vm.isReadOnlyUser,"check-box-click":_vm.checkBoxClick,"format-date":_vm.formatDate,"enterprise-vat-rate":_vm.enterpriseVatRate,"view-expense":_vm.viewExpense,"select-all-click":_vm.selectAllClick,"selected-expenses":_vm.selectedExpenses,"status":_vm.status}}):_vm._e()],1)])})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }