<script>
export default {
  props: {
    expenses: {
      type: Array,
      default: () => null,
    },
    status: {
      type: String,
      default: () => null,
    },
    summary: {
      type: Array,
      default: () => [],
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    setPage: {
      type: Function,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checkBoxClick: {
      type: Function,
      default: () => [],
    },
    selectAllClick: {
      type: Function,
      default: () => [],
    },
    formatDate: {
      type: Function,
      default: () => [],
    },
    enterpriseVatRate: {
      type: Number,
      default: 100,
    },
    viewExpense: {
      type: Function,
      default: () => {},
    },
    selectedExpenses: {
      type: Array,
      default: () => null,
    },
  },
}
</script>

<template functional>
  <div :class="$style.content">
    <v-simple-table class="expenses">
      <thead>
        <tr>
          <th
            v-if="
              !props.disabled &&
              ['NEED_MORE_INFO', 'DESELECTED'].includes(props.status)
            "
            class="text-left"
            :class="$style.checkbox"
          >
            <v-tooltip max-width="200px" top>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-checkbox
                    :input-value="
                      props.expenses.every((expense) =>
                        props.selectedExpenses.includes(expense.id)
                      )
                    "
                    color="blue darken-2"
                    :ripple="false"
                    hide-details
                    dense
                    @change="(value) => props.selectAllClick(value)"
                  ></v-checkbox>
                </div>
              </template>
              <span>{{ parent.$t('SELECT_ALL') }}</span>
            </v-tooltip>
          </th>
          <th :class="$style.date">{{ parent.$t('DATE') }}</th>
          <th :class="$style.merchant">{{ parent.$t('MERCHANT_NAME') }}</th>
          <th :class="$style.merchantId">{{ parent.$t('MERCHANT_ID') }}</th>
          <th :class="$style.scac">{{ parent.$t('SCAC_CODE') }}</th>
          <th :class="$style.amount">{{ parent.$t('AMOUNT_INCL_VAT') }}</th>
          <th :class="$style.reclaim">{{ parent.$t('RECLAIM') }}</th>
          <th class="text-xs-center" :class="$style.details">{{
            parent.$t('DETAILS')
          }}</th>
        </tr>
      </thead>

      <tbody v-if="props.expenses.length">
        <tr v-for="item in props.expenses" :key="item.id" :class="$style.tr">
          <td
            v-if="
              !props.disabled &&
              ['NEED_MORE_INFO', 'DESELECTED'].includes(props.status)
            "
            :class="$style.checkbox"
          >
            <v-checkbox
              :input-value="props.selectedExpenses.find((id) => id === item.id)"
              :ripple="false"
              type="checkbox"
              @change="(value) => props.checkBoxClick({ value, id: item.id })"
            />
          </td>
          <td>{{ props.formatDate(item.date) }}</td>
          <td>{{ item.merchant.name }}</td>
          <td>{{ item.merchant.number }}</td>
          <td>
            {{
              `${item.scac.major ? item.scac.major : ''}${
                item.scac.minor ? `.${item.scac.minor}` : ''
              }${item.scac.patch ? `.${item.scac.patch}` : ''}`
            }}
          </td>
          <td :class="$style.amountReclaimVat">
            {{
              `${parent.$tn(item.amount_ex_vat + item.vat_amount)}
            ${item.currency}`
            }}
          </td>
          <td :class="$style.amountReclaim">
            {{ `${parent.$tn(item.reclaim_amount)} ${item.currency}` }}
          </td>
          <td class="text-xs-center">
            <v-btn
              text
              class="naked-btn primary bold smaller"
              @click="props.viewExpense(item.id)"
              >{{ parent.$t('SHOW') }}</v-btn
            >
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td>...</td>
          <td>{{ parent.$t('NO_EXPENSES_FOR_SELECTED_CRITERIA') }}</td>
        </tr>
      </tbody>
    </v-simple-table>
    <!-- <NakedDropdown
        :label="$t('EXPENSES_PER_VIEW')"
        :items="[10, 25, 50, 100]"
        :value="pagination.pageSize"
        :default-value="pagination.pageSize"
        color="#fff"
        dark
        @change="(pageSize) => setPageSize(pageSize)"
    ></NakedDropdown>-->
    <v-pagination
      :class="$style.pagination"
      class="custom"
      :value="props.pagination.page"
      :next-icon="parent.$t('NEXT')"
      :prev-icon="parent.$t('PREVIOUS')"
      :length="Math.ceil(props.pagination.total / props.pagination.pageSize)"
      @input="(page) => props.setPage(page)"
    ></v-pagination>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.tr {
  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.date,
.merchantId {
  width: 150px;
}

.date {
  padding-left: 15px;
}

.merchantId {
  width: 150px;
}

.scac {
  width: 115px;
}

.details {
  width: 100px;
}

.reclaim {
  width: 140px;
  text-align: right !important;
}

.amount {
  width: 150px;
  text-align: right !important;
}

.checkbox {
  width: 30px;
}

.pagination {
  padding: 10px 0 50px;
}
.amountReclaim,
.amountReclaimVat {
  text-align: right;
}
</style>
