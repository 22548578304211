var render = function render(_c,_vm){return _c('div',{class:_vm.$style.content},[_c('v-simple-table',{staticClass:"expenses"},[_c('thead',[_c('tr',[(
            !_vm.props.disabled &&
            ['NEED_MORE_INFO', 'DESELECTED'].includes(_vm.props.status)
          )?_c('th',{staticClass:"text-left",class:_vm.$style.checkbox},[_c('v-tooltip',{attrs:{"max-width":"200px","top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('div',_vm._g({},on),[_c('v-checkbox',{attrs:{"input-value":_vm.props.expenses.every((expense) =>
                      _vm.props.selectedExpenses.includes(expense.id)
                    ),"color":"blue darken-2","ripple":false,"hide-details":"","dense":""},on:{"change":(value) => _vm.props.selectAllClick(value)}})],1)]}}],null,false,2834874456)},[_c('span',[_vm._v(_vm._s(_vm.parent.$t('SELECT_ALL')))])])],1):_vm._e(),_c('th',{class:_vm.$style.date},[_vm._v(_vm._s(_vm.parent.$t('DATE')))]),_c('th',{class:_vm.$style.merchant},[_vm._v(_vm._s(_vm.parent.$t('MERCHANT_NAME')))]),_c('th',{class:_vm.$style.merchantId},[_vm._v(_vm._s(_vm.parent.$t('MERCHANT_ID')))]),_c('th',{class:_vm.$style.scac},[_vm._v(_vm._s(_vm.parent.$t('SCAC_CODE')))]),_c('th',{class:_vm.$style.amount},[_vm._v(_vm._s(_vm.parent.$t('AMOUNT_INCL_VAT')))]),_c('th',{class:_vm.$style.reclaim},[_vm._v(_vm._s(_vm.parent.$t('RECLAIM')))]),_c('th',{staticClass:"text-xs-center",class:_vm.$style.details},[_vm._v(_vm._s(_vm.parent.$t('DETAILS')))])])]),(_vm.props.expenses.length)?_c('tbody',_vm._l((_vm.props.expenses),function(item){return _c('tr',{key:item.id,class:_vm.$style.tr},[(
            !_vm.props.disabled &&
            ['NEED_MORE_INFO', 'DESELECTED'].includes(_vm.props.status)
          )?_c('td',{class:_vm.$style.checkbox},[_c('v-checkbox',{attrs:{"input-value":_vm.props.selectedExpenses.find((id) => id === item.id),"ripple":false,"type":"checkbox"},on:{"change":(value) => _vm.props.checkBoxClick({ value, id: item.id })}})],1):_vm._e(),_c('td',[_vm._v(_vm._s(_vm.props.formatDate(item.date)))]),_c('td',[_vm._v(_vm._s(item.merchant.name))]),_c('td',[_vm._v(_vm._s(item.merchant.number))]),_c('td',[_vm._v(" "+_vm._s(`${item.scac.major ? item.scac.major : ''}${ item.scac.minor ? `.${item.scac.minor}` : '' }${item.scac.patch ? `.${item.scac.patch}` : ''}`)+" ")]),_c('td',{class:_vm.$style.amountReclaimVat},[_vm._v(" "+_vm._s(`${_vm.parent.$tn(item.amount_ex_vat + item.vat_amount)} ${item.currency}`)+" ")]),_c('td',{class:_vm.$style.amountReclaim},[_vm._v(" "+_vm._s(`${_vm.parent.$tn(item.reclaim_amount)} ${item.currency}`)+" ")]),_c('td',{staticClass:"text-xs-center"},[_c('v-btn',{staticClass:"naked-btn primary bold smaller",attrs:{"text":""},on:{"click":function($event){return _vm.props.viewExpense(item.id)}}},[_vm._v(_vm._s(_vm.parent.$t('SHOW')))])],1)])}),0):_c('tbody',[_c('tr',[_c('td',[_vm._v("...")]),_c('td',[_vm._v(_vm._s(_vm.parent.$t('NO_EXPENSES_FOR_SELECTED_CRITERIA')))])])])]),_c('v-pagination',{staticClass:"custom",class:_vm.$style.pagination,attrs:{"value":_vm.props.pagination.page,"next-icon":_vm.parent.$t('NEXT'),"prev-icon":_vm.parent.$t('PREVIOUS'),"length":Math.ceil(_vm.props.pagination.total / _vm.props.pagination.pageSize)},on:{"input":(page) => _vm.props.setPage(page)}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }