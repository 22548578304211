<script>
import ExpenseList from '@components/expense-list/expense-list'
import ICONS from '@utils/categoryIcons'
import CustomLoader from '@components/custom-loader/custom-loader'
import formatDate from '@utils/format-date'
import { getSavedStateSession } from '@utils/storage'

export default {
  components: { ExpenseList, CustomLoader },
  props: {
    categories: {
      type: Array,
      default: () => null,
    },
    category: {
      type: String,
      default: () => null,
    },
    country: {
      type: String,
      default: () => null,
    },
    status: {
      type: String,
      default: () => null,
    },
    categoryOverview: {
      type: Array,
      default: () => [],
    },
    expenses: {
      type: Array,
      default: () => null,
    },
    selectCategory: {
      type: Function,
      default: () => null,
    },
    pagination: {
      type: Object,
      default: () => {},
    },
    setPage: {
      type: Function,
      default: () => [],
    },
    checkBoxClick: {
      type: Function,
      default: () => [],
    },
    selectAllClick: {
      type: Function,
      default: () => [],
    },
    enterpriseVatRate: {
      type: Number,
      default: 100,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    selectedExpenses: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      ICONS,
      formatDate,
      isReadOnlyUser: getSavedStateSession('isReadOnlyUser'),
    }
  },
  computed: {
    categoriesComputed() {
      // Add isEmpty property for each category
      if (!this.categories) return []
      const categories = this.categories.map((category) => {
        category.isEmpty = false
        const categoryOverviewItem = this.categoryOverview.find(
          (el) => el.category === category.key
        )
        if (
          !categoryOverviewItem ||
          !(
            categoryOverviewItem.expenses_in_selected_status ||
            categoryOverviewItem.number_of_expenses
          )
        ) {
          category.isEmpty = true
        }
        return category
      })
      return categories.sort((a, b) => a.isEmpty - b.isEmpty) // Empty categories go to the bottom
    },
  },
  methods: {
    getExpensesCount(category) {
      const record = this.categoryOverview.find(
        (item) => category.key === item.category
      )
      return record
        ? record.expenses_in_selected_status || record.number_of_expenses || 0
        : 0
    },
    getReclaimValue(category) {
      const record = this.categoryOverview.find(
        (item) => category.key === item.category
      )
      const reclaimValue = record
        ? record.potential_reclaim_sum_enterprise_currency ||
          record.reclaim_sum_converted ||
          0
        : 0
      return reclaimValue
        ? `${this.$tn(reclaimValue)} ${
            record.enterprise_currency || record.converted_currency
          }`
        : 0
    },
    onSelectCategory(index) {
      if (this.categoriesComputed && !this.categoriesComputed[index].isEmpty) {
        if (this.categoriesComputed[index].key !== this.category) {
          // Category is collapsed
          this.$amplitude.getInstance().logEvent('EXPAND_CATEGORY', {
            category: this.categoriesComputed[index].key,
          })
          this.selectCategory(this.categoriesComputed[index].key)
        } else {
          // Category is expanded
          this.selectCategory(null)
        }
      }
    },
    viewExpense(expenseId) {
      this.$store.dispatch('expenseModal/initializeExpense', { id: expenseId })
      window.history.pushState({}, null, `${this.$route.path}/${expenseId}`)
      return this.$amplitude.getInstance().logEvent('VIEW_EXPENSE', {
        expenseId: expenseId,
      })
    },
  },
}
</script>

<template>
  <div :class="$style.categoryWrap">
    <div v-if="loading" :class="$style.overlay">
      <CustomLoader />
    </div>
    <div
      v-for="(item, index) in categoriesComputed"
      :key="item.code"
      :class="$style.item"
    >
      <div
        :key="item.code"
        :class="[
          $style.expenseContainer,
          item.key === category ? $style.active : null,
          categoriesComputed[index].isEmpty ? $style.empty : null,
        ]"
        @click="onSelectCategory(index)"
      >
        <div :class="$style.nameIcon">
          <div :class="$style.icon">
            <v-icon
              :disabled="categoriesComputed[index].isEmpty"
              color="blue"
              >{{ ICONS[item.key] }}</v-icon
            >
          </div>
          <div :class="$style.name">{{ item.name }}</div>
        </div>

        <div :class="$style.info">
          <div :class="$style.reclaimValue">
            <span>{{ $t('TOTAL_VAT_RECLAIM_AMOUNT') }}</span>
            {{ getReclaimValue(item) }}
          </div>
          <div :class="$style.expenses">
            <span>{{ $t('EXPENSES') }}</span>
            {{ getExpensesCount(item) }}
          </div>
        </div>
      </div>
      <div
        :class="[
          $style.expensesList,
          item.key === category ? $style.expanded : null,
        ]"
      >
        <ExpenseList
          v-if="item.key === category"
          :expenses="expenses"
          :pagination="pagination"
          :set-page="setPage"
          :disabled="isReadOnlyUser"
          :check-box-click="checkBoxClick"
          :format-date="formatDate"
          :enterprise-vat-rate="enterpriseVatRate"
          :view-expense="viewExpense"
          :select-all-click="selectAllClick"
          :selected-expenses="selectedExpenses"
          :status="status"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
@import '@design';

.item {
  background-color: $light-grey;

  .loader {
    padding: 0;
    margin: 20px 0;
    color: white;
  }
  .expenseContainer {
    position: relative;
    display: flex;
    align-content: space-between;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    padding: 0 40px 0 18px;
    font-size: 16px;
    color: $dark;
    background: white;
    border-bottom: 1px solid rgba($grey, 0.18);
    &::after {
      position: absolute;
      right: 20px;
      width: 50px;
      height: 50px;
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%23222F44' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 7L7 1 1 7'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 14px 8px;
      transition: all 0.3s cubic-bezier(0.36, 0.69, 0.43, 1.16);
      transform: rotate(180deg);
    }

    &:hover {
      cursor: pointer;
    }

    .nameIcon {
      display: flex;
      .icon i {
        width: 26px;
        height: 26px;
        margin: -4px 10px 0 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 26px;
      }
      .name {
        font-weight: 600;
      }
    }
    .info {
      display: flex;
      margin-right: 0;
      font-weight: 700;
      text-align: right;

      .expenses {
        min-width: 130px;
        text-align: left;
      }
      .reclaimValue {
        text-align: left;
      }

      span {
        margin-right: 10px;
        font-weight: 200;
        color: #8c949e;
      }
      div {
        margin-right: 45px;
      }
    }
  }
}
.categoryWrap {
  position: relative;
  top: -5px;
  margin: 0 40px 50px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
  }
}
.empty {
  color: $grey !important;
  &:hover {
    cursor: default !important;
  }
  &::after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 8'%3E%3Cpath fill='none' fill-rule='evenodd' stroke='%238c949e' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M13 7L7 1 1 7'/%3E%3C/svg%3E") !important;
  }
}

// Expense list animation effect would go here
// .expensesList {
// }
// .expanded {
//   // And here
// }
.active {
  &::after {
    transition: all 0.3s cubic-bezier(0.36, 0.69, 0.43, 1.16) !important;
    transform: rotate(90deg) !important;
  }
}
</style>
